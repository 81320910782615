footer {
	.footerContainer {
		.questions {
			p {
				img.garantee {
					width: 150px;
					height: auto;
					margin: 10px 0;
				}
			}
		}
	}
}